var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-add" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.editItem()
                }
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("gameSetting.add")) + "\n    ")]
          ),
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              type: "daterange",
              align: "right",
              "unlink-panels": "",
              "range-separator": _vm.$t("statManager.to"),
              "start-placeholder": _vm.$t("statManager.startTime"),
              "end-placeholder": _vm.$t("statManager.endTime"),
              "picker-options": _vm.pickerOptions2
            },
            model: {
              value: _vm.rangeTime2,
              callback: function($$v) {
                _vm.rangeTime2 = $$v
              },
              expression: "rangeTime2"
            }
          }),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("statManager.search")) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.list, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", "min-width": "200" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "title",
              label: _vm.$t("gameSetting.title"),
              "min-width": "120"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "version",
              label: _vm.$t("gameSetting.version"),
              "min-width": "120"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "isRestart",
              label: _vm.$t("gameSetting.isRestart"),
              "min-width": "120"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "restartTime",
              label: _vm.$t("gameSetting.restartTime"),
              "min-width": "120"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "type",
              label: _vm.$t("gameSetting.type"),
              "min-width": "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("gameSetting." + scope.row.type)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "activity",
              label: _vm.$t("gameSetting.activity"),
              "min-width": "300"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.type === "image"
                      ? _c("span", { staticClass: "text-nowrap" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(scope.row.imageUrl) +
                              " -> " +
                              _vm._s(scope.row.jumpUrl) +
                              "\n        "
                          )
                        ])
                      : scope.row.type !== "image"
                      ? _c("span", { staticClass: "text-nowrap" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(scope.row.activity) +
                              "\n        "
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "period",
              label: _vm.$t("gameSetting.period"),
              "min-width": "220"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.formattedDate(scope.row.startTime, "YYYY-MM-DD")
                        ) +
                          " -> " +
                          _vm._s(
                            _vm.formattedDate(scope.row.endTime, "YYYY-MM-DD")
                          )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "place",
              label: _vm.$t("gameSetting.place"),
              "min-width": "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("gameSetting." + scope.row.place)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "times",
              label: _vm.$t("gameSetting.times"),
              "min-width": "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("gameSetting." + scope.row.times)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "time",
              label: _vm.$t("gameSetting.time"),
              "min-width": "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formattedDate(scope.row.time)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateTime",
              label: _vm.$t("gameSetting.updateTime"),
              "min-width": "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.formattedDate(
                            scope.row.updateTime || scope.row.time
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: _vm.$t("gameSetting.operate"),
              "min-width": "160"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.isString(scope.row.id)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.editItem(scope.row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("gameSetting.edit")) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.isString(scope.row.id)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.deleteItem(scope.row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("gameSetting.delete")) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("gameSetting.addAndEdit"),
            visible: _vm.dialogEditorVisible,
            "modal-append-to-body": false,
            "before-close": _vm.onDialogClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogEditorVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              staticClass: "activity-form",
              attrs: {
                model: _vm.formData,
                rules: _vm.ruleData,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: { prop: "title", label: _vm.$t("gameSetting.title") }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("gameSetting.inputTitle")
                    },
                    model: {
                      value: _vm.formData.title,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "title", $$v)
                      },
                      expression: "formData.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "version",
                    label: _vm.$t("gameSetting.version")
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("gameSetting.inputVersion")
                    },
                    model: {
                      value: _vm.formData.version,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "version", $$v)
                      },
                      expression: "formData.version"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "type", label: _vm.$t("gameSetting.type") } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("gameSetting.selectType") },
                      on: {
                        change: function($event) {
                          return _vm.selectTrigger()
                        }
                      },
                      model: {
                        value: _vm.formData.type,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "type", $$v)
                        },
                        expression: "formData.type"
                      }
                    },
                    _vm._l(_vm.types || [], function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: _vm.$t(item.dec), value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.showData
                ? _c(
                    "div",
                    [
                      _vm.formData.type === "image"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "imageUrl",
                                label: _vm.$t("gameSetting.imageUrl")
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.$t(
                                    "gameSetting.inputImageUrl"
                                  )
                                },
                                model: {
                                  value: _vm.formData.imageUrl,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "imageUrl", $$v)
                                  },
                                  expression: "formData.imageUrl"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.formData.type === "image"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "jumpUrl",
                                label: _vm.$t("gameSetting.jumpUrl")
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.$t(
                                    "gameSetting.inputJumpUrl"
                                  )
                                },
                                model: {
                                  value: _vm.formData.jumpUrl,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "jumpUrl", $$v)
                                  },
                                  expression: "formData.jumpUrl"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.formData.type !== "image"
                        ? _c(
                            "div",
                            _vm._l(_vm.formData.activity, function(
                              item,
                              index
                            ) {
                              return _c(
                                "el-form-item",
                                {
                                  key: index,
                                  attrs: {
                                    label: _vm.$t("gameSetting.notice") + index,
                                    prop: "activity." + index + ".value",
                                    rules: {
                                      required: true,
                                      message: _vm.$t("gameSetting.notEmpty"),
                                      trigger: "blur"
                                    }
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { type: "textarea" },
                                    model: {
                                      value: item.value,
                                      callback: function($$v) {
                                        _vm.$set(item, "value", $$v)
                                      },
                                      expression: "item.value"
                                    }
                                  }),
                                  index > 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "danger",
                                            icon: "minus"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.removeActivity(item)
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-minus"
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm.formData.type !== "image"
                        ? _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", icon: "plus" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.addActivity()
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-plus" })]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: { prop: "period", label: _vm.$t("gameSetting.period") }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "filter-item",
                    staticStyle: { width: "300px" },
                    attrs: {
                      type: "daterange",
                      align: "right",
                      "unlink-panels": "",
                      "range-separator": _vm.$t("statManager.to"),
                      "start-placeholder": _vm.$t("statManager.startTime"),
                      "end-placeholder": _vm.$t("statManager.endTime"),
                      "picker-options": _vm.pickerOptions
                    },
                    model: {
                      value: _vm.rangeTime,
                      callback: function($$v) {
                        _vm.rangeTime = $$v
                      },
                      expression: "rangeTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { prop: "times", label: _vm.$t("gameSetting.times") }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("gameSetting.selectTimesType")
                      },
                      model: {
                        value: _vm.formData.times,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "times", $$v)
                        },
                        expression: "formData.times"
                      }
                    },
                    _vm._l(_vm.timesTypes || [], function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: _vm.$t(item.dec), value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { prop: "place", label: _vm.$t("gameSetting.place") }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("gameSetting.selectPlaceType")
                      },
                      model: {
                        value: _vm.formData.place,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "place", $$v)
                        },
                        expression: "formData.place"
                      }
                    },
                    _vm._l(_vm.placeTypes || [], function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: _vm.$t(item.dec), value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "isRestart",
                    label: _vm.$t("gameSetting.isRestart")
                  }
                },
                [
                  _c("el-checkbox", {
                    on: { change: _vm.showRestartTime },
                    model: {
                      value: _vm.formData.isRestart,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "isRestart", $$v)
                      },
                      expression: "formData.isRestart"
                    }
                  })
                ],
                1
              ),
              _vm.restartTimeVisible
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "restartTime",
                        label: _vm.$t("gameSetting.restartTime")
                      }
                    },
                    [
                      _c("el-date-picker", {
                        staticClass: "filter-item",
                        staticStyle: { width: "300px" },
                        attrs: {
                          type: "datetime",
                          placeholder: _vm.$t("gameSetting.selectRestartTime"),
                          "value-format": "yyyy-MM-dd HH:mm",
                          format: "yyyy-MM-dd HH:mm",
                          align: "right",
                          "unlink-panels": ""
                        },
                        model: {
                          value: _vm.formData.restartTime,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "restartTime", $$v)
                          },
                          expression: "formData.restartTime"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("el-form-item", [
                _c(
                  "button",
                  {
                    staticClass: "el-button el-button--primary",
                    attrs: { type: "submit" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.saveOrUpdate("formData")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("gameSetting.save")) +
                        "\n        "
                    )
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }