var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-add" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.editItem()
                }
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("default.add")) + "\n    ")]
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("statManager.search")) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.dataObj && _vm.dataObj.rows, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", "min-width": "80" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "productType",
              label: _vm.$t("operation.productType"),
              "min-width": "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getProductType(scope.row.productType)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "productName",
              label: _vm.$t("operation.productName"),
              "min-width": "120"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "productDesc",
              label: _vm.$t("operation.productDesc"),
              "min-width": "120"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "productDesc2",
              label: _vm.$t("operation.productDesc2"),
              "min-width": "120"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "codeName",
              label: _vm.$t("operation.codeName"),
              "min-width": "120"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "costType",
              label: _vm.$t("operation.costType"),
              "min-width": "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("default." + scope.row.costType)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "productPrice",
              label: _vm.$t("operation.productPrice"),
              "min-width": "60"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "recoveryPrice",
              label: _vm.$t("operation.recoveryPrice"),
              "min-width": "60"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.recoveryPrice))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "charmValue",
              label: _vm.$t("operation.charmValue"),
              "min-width": "60"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "productStatus",
              label: _vm.$t("operation.productStatus"),
              "min-width": "60"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.getProductStatus(scope.row.productStatus)) +
                          " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "vipType",
              label: _vm.$t("operation.vipType"),
              "min-width": "60"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getVipType(scope.row.vipType)) + " ")
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "period",
              label: _vm.$t("operation.period"),
              "min-width": "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getPeriodType(scope.row.period)) + " ")
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "weight",
              label: _vm.$t("operation.weight"),
              "min-width": "60"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "isShowSend",
              label: _vm.$t("gameSetting.isShowSend"),
              "min-width": "30"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "vipFreeExTimes",
              label: _vm.$t("gameSetting.vipFreeExTimes"),
              "min-width": "30"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: _vm.$t("default.createTime"),
              "min-width": "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formattedDate(scope.row.createTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateTime",
              label: _vm.$t("default.updateTime"),
              "min-width": "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formattedDate(scope.row.updateTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: _vm.$t("default.operate"),
              "min-width": "160"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.editItem(scope.row)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("default.edit")) +
                            "\n        "
                        )
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger" },
                        on: {
                          click: function($event) {
                            return _vm.deleteItem(scope.row)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("default.delete")) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("operation.addAndEdit"),
            visible: _vm.dialogEditorVisible,
            "modal-append-to-body": false,
            "before-close": _vm.onDialogClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogEditorVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              staticClass: "activity-form",
              attrs: {
                model: _vm.formData,
                rules: _vm.ruleData,
                "label-width": "160px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "productType",
                    label: _vm.$t("operation.productType")
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("operation.selectProductType")
                      },
                      model: {
                        value: _vm.formData.productType,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "productType", $$v)
                        },
                        expression: "formData.productType"
                      }
                    },
                    _vm._l(_vm.productTypes || [], function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: _vm.$t(item.dec), value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "productName",
                    label: _vm.$t("operation.productName")
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("operation.inpurtProductName")
                    },
                    model: {
                      value: _vm.formData.productName,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "productName", $$v)
                      },
                      expression: "formData.productName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "productDesc",
                    label: _vm.$t("operation.productDesc")
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: _vm.$t("operation.inputProductDesc")
                    },
                    model: {
                      value: _vm.formData.productDesc,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "productDesc", $$v)
                      },
                      expression: "formData.productDesc"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "productDesc2",
                    label: _vm.$t("operation.productDesc2")
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: _vm.$t("operation.inputProductDesc")
                    },
                    model: {
                      value: _vm.formData.productDesc2,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "productDesc2", $$v)
                      },
                      expression: "formData.productDesc2"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "codeName",
                    label: _vm.$t("operation.codeName")
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("operation.inpurtCodeName")
                    },
                    model: {
                      value: _vm.formData.codeName,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "codeName", $$v)
                      },
                      expression: "formData.codeName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "costType",
                    label: _vm.$t("operation.costType")
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("operation.selectCostType")
                      },
                      model: {
                        value: _vm.formData.costType,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "costType", $$v)
                        },
                        expression: "formData.costType"
                      }
                    },
                    _vm._l(_vm.costTypes || [], function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: _vm.$t(item.dec), value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "productPrice",
                    label: _vm.$t("operation.productPrice")
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "number",
                      placeholder: _vm.$t("operation.inputProductPrice"),
                      min: 1,
                      max: 1000000
                    },
                    model: {
                      value: _vm.formData.productPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "productPrice", $$v)
                      },
                      expression: "formData.productPrice"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "recoveryPrice",
                    label: _vm.$t("operation.recoveryPrice")
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "number",
                      placeholder: _vm.$t("operation.inputRecoveryPrice"),
                      min: 1,
                      max: 1000000
                    },
                    model: {
                      value: _vm.formData.recoveryPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "recoveryPrice", $$v)
                      },
                      expression: "formData.recoveryPrice"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "charmValue",
                    label: _vm.$t("operation.charmValue")
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "number",
                      placeholder: _vm.$t("operation.inputCharmValue"),
                      min: 0,
                      max: 1000
                    },
                    model: {
                      value: _vm.formData.charmValue,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "charmValue", $$v)
                      },
                      expression: "formData.charmValue"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "productStatus",
                    label: _vm.$t("operation.productStatus")
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("operation.selectProductStatus")
                      },
                      model: {
                        value: _vm.formData.productStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "productStatus", $$v)
                        },
                        expression: "formData.productStatus"
                      }
                    },
                    _vm._l(_vm.productStatuses || [], function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: _vm.$t(item.dec), value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { prop: "vipType", label: _vm.$t("operation.vipType") }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("operation.selectVipType") },
                      model: {
                        value: _vm.formData.vipType,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "vipType", $$v)
                        },
                        expression: "formData.vipType"
                      }
                    },
                    _vm._l(_vm.vipTypes || [], function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: _vm.$t(item.dec), value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "period",
                    label: _vm.$t("operation.periodType")
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("operation.selectPeriodType")
                      },
                      model: {
                        value: _vm.formData.period,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "period", $$v)
                        },
                        expression: "formData.period"
                      }
                    },
                    _vm._l(_vm.periodTypes || [], function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: _vm.$t(item.dec), value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { prop: "weight", label: _vm.$t("operation.weight") }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "number",
                      placeholder: _vm.$t("operation.inputWeight"),
                      min: 1,
                      max: 1000
                    },
                    model: {
                      value: _vm.formData.weight,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "weight", $$v)
                      },
                      expression: "formData.weight"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "isShowSend",
                    label: _vm.$t("gameSetting.isShowSend")
                  }
                },
                [
                  _c("el-checkbox", {
                    model: {
                      value: _vm.formData.isShowSend,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "isShowSend", $$v)
                      },
                      expression: "formData.isShowSend"
                    }
                  })
                ],
                1
              ),
              _vm.formData.productType === "1"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "vipFreeExTimes",
                        label: _vm.$t("gameSetting.vipFreeExTimes")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "number",
                          placeholder: _vm.$t("operation.inputVipFreeExTimes"),
                          min: 1,
                          max: 1000
                        },
                        model: {
                          value: _vm.formData.vipFreeExTimes,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "vipFreeExTimes", $$v)
                          },
                          expression: "formData.vipFreeExTimes"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("el-form-item", [
                _c(
                  "button",
                  {
                    staticClass: "el-button el-button--primary",
                    attrs: { type: "submit" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.saveOrUpdate("formData")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("default.save")) +
                        "\n        "
                    )
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }