var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-add" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.editItem()
                }
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("gameSetting.add")) + "\n    ")]
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("statManager.search")) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.list, border: "" }
        },
        [
          false
            ? _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "200" }
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              prop: "bannerType",
              label: _vm.$t("gameSetting.bannerType"),
              "min-width": "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getBannerName(scope.row.bannerType)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "remark",
              label: _vm.$t("gameSetting.remark"),
              "min-width": "120"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "imageUrl",
              label: _vm.$t("gameSetting.imageUrl"),
              "min-width": "120"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "level",
              label: _vm.$t("gameSetting.level"),
              "min-width": "120"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "type",
              label: _vm.$t("gameSetting.type"),
              "min-width": "120"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "type",
              label: _vm.$t("gameSetting.isShelf"),
              "min-width": "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          !scope.row.startTime
                            ? _vm.$t("gameSetting.yes")
                            : _vm.$t("gameSetting.no")
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "period",
              label: _vm.$t("gameSetting.period"),
              "min-width": "220"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.startTime
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.formattedDate(
                                  scope.row.startTime,
                                  "YYYY-MM-DD"
                                )
                              ) +
                                " -> " +
                                _vm._s(
                                  _vm.formattedDate(
                                    scope.row.endTime,
                                    "YYYY-MM-DD"
                                  )
                                )
                            )
                          ])
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateTime",
              label: _vm.$t("gameSetting.updateTime"),
              "min-width": "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.formattedDate(
                            scope.row.updateTime || scope.row.time
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: _vm.$t("gameSetting.operate"),
              "min-width": "160"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.editItem(scope.row)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("gameSetting.edit")) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm.isString(scope.row.id)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.deleteItem(scope.row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("gameSetting.delete")) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("gameSetting.addAndEditBanner"),
            visible: _vm.dialogEditorVisible,
            "modal-append-to-body": false,
            "before-close": _vm.onDialogClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogEditorVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              staticClass: "activity-form",
              attrs: {
                model: _vm.formData,
                rules: _vm.ruleData,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "bannerType",
                    label: _vm.$t("gameSetting.bannerType")
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("gameSetting.inputBannerType")
                      },
                      model: {
                        value: _vm.formData.bannerType,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "bannerType", $$v)
                        },
                        expression: "formData.bannerType"
                      }
                    },
                    _vm._l(_vm.isShelf1 || [], function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: _vm.$t(item.dec), value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { prop: "type", label: _vm.$t("gameSetting.remark") }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("gameSetting.inputRemark")
                    },
                    model: {
                      value: _vm.formData.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "remark", $$v)
                      },
                      expression: "formData.remark"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "imageUrl",
                    label: _vm.$t("gameSetting.imageUrl")
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("gameSetting.inputImageUrl")
                    },
                    model: {
                      value: _vm.formData.imageUrl,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "imageUrl", $$v)
                      },
                      expression: "formData.imageUrl"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "type", label: _vm.$t("gameSetting.type") } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("gameSetting.inputType")
                    },
                    model: {
                      value: _vm.formData.type,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "type", $$v)
                      },
                      expression: "formData.type"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "type", label: _vm.$t("gameSetting.level") } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("gameSetting.inputLevel")
                    },
                    model: {
                      value: _vm.formData.level,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "level", $$v)
                      },
                      expression: "formData.level"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "isShelf",
                    label: _vm.$t("gameSetting.isShelf")
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("gameSetting.selectIsShelf")
                      },
                      on: {
                        change: function($event) {
                          return _vm.selectTrigger()
                        }
                      },
                      model: {
                        value: _vm.formData.isShelf,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "isShelf", $$v)
                        },
                        expression: "formData.isShelf"
                      }
                    },
                    _vm._l(_vm.isShelf || [], function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: _vm.$t(item.dec), value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.showData
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "period",
                            label: _vm.$t("gameSetting.isShelfPeriod")
                          }
                        },
                        [
                          _c("el-date-picker", {
                            staticClass: "filter-item",
                            staticStyle: { width: "300px" },
                            attrs: {
                              type: "daterange",
                              align: "right",
                              "unlink-panels": "",
                              "range-separator": _vm.$t("statManager.to"),
                              "start-placeholder": _vm.$t(
                                "statManager.startTime"
                              ),
                              "end-placeholder": _vm.$t("statManager.endTime"),
                              "picker-options": _vm.pickerOptions
                            },
                            model: {
                              value: _vm.rangeTime,
                              callback: function($$v) {
                                _vm.rangeTime = $$v
                              },
                              expression: "rangeTime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("el-form-item", [
                _c(
                  "button",
                  {
                    staticClass: "el-button el-button--primary",
                    attrs: { type: "submit" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.saveOrUpdate("formData")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("gameSetting.save")) +
                        "\n        "
                    )
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }